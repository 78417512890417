const aenv = process.env.ANIMATRON_ENV;
const env = !!aenv ? aenv: process.env.STATIC_ENV

const tsEnv = process.env.TS_ENV

export const isTest = env === 'test'
export const isProd = env === 'prod'
export const isDev = env === 'dev'

export const isUnitTest = tsEnv === 'test' && isDev


