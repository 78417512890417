import { Logger } from '@anm/helpers/Debugger';
import { BrowserOptions } from '@sentry/browser';
import * as Sentry from '@sentry/react';
import { User } from 'types/user';

import isServer from '../is/isServer';
import isUnitTesting from '../testing/isUnitTesting';

declare global {
  interface Window {
    build?: string;
  }
}

const env = process.env.TS_ENV;
const build = process.env.BUILD_NUMBER;

if (!isServer()) {
  window['build'] = process.env.BUILD_NUMBER;
}

const isEnabled = (env === 'test' || env === 'prod') && !isUnitTesting;
const logger = new Logger('Sentry');

const errorHandler = (...args: unknown[]) => {
  let msg = '';
  for (const a of args) {
    if (a instanceof Error) {
      const e = a as Error;
      Sentry.setExtra('exceptionMessage', msg);
      Sentry.captureException(e);
      return;
    } else if (typeof a !== 'object') {
      msg += a;
    } else {
      try {
        msg += ' ' + JSON.stringify(a);
      } catch (e) {
        msg += ' ' + a;
      }
    }
  }
  Sentry.captureMessage(msg);
};

export type SentryProps = Required<Pick<BrowserOptions, 'dsn'>> & Partial<BrowserOptions> & {};

const defaultIntegrations = [new Sentry.Integrations.TryCatch(), new Sentry.Integrations.Dedupe()];

export const init = ({ dsn, release, integrations = defaultIntegrations }: SentryProps) => {
  if (!isEnabled) return;

  try {
    Sentry.init({
      dsn,
      integrations,
      release: `${release}-${env}#${build}`,
      environment: `${env}`,
      maxValueLength: 1024
    });
  } catch (err) {
    logger.error(err);
    return;
  }

  Logger.errorHandler = errorHandler;
};

export const setUser = (userProfile: User | null) => {
  if (isEnabled && userProfile) {
    const { email, username, userId } = userProfile;
    Sentry.setUser({ email, username, id: userId });
  }
};
