import * as cookie from '@anm/helpers/cookie';

import { AnalyticProvider } from './types';

declare global {
  interface Window {
    ga: any;
  }
}

const ga = (...arg: any[]) => window.ga && window.ga(...arg);

export const load = () => {
  /* tslint:disable */
  (function(i: any, s: any, o: string, g: string, r: string, a?: any) {
    i['GoogleAnalyticsObject'] = r;
    (i[r] =
      i[r] ||
      function() {
        (i[r].q = i[r].q || []).push(arguments);
      }),
      (i[r].l = 1 * (new Date() as any));
    a = s.createElement(o);
    a.defer = 1;
    a.src = g;

    function load() {
      setTimeout(() => document.body.appendChild(a), 1500);
    }
    window.addEventListener('load', load, false);
  })(
    window,
    document,
    'script',
    'https://www.google-analytics.com/analytics.js',
    'ga'
  );
};

export const init = ({ id, domain }: AnalyticProvider) => {
  ga('create', id, 'auto', {
    allowLinker: true,
    cookieDomain: cookie.getDomain()
  });
  ga('require', 'ecommerce');
  ga('require', 'linker');
  ga('linker:autoLink', [domain]);
};

export const getGaHeaders = () => ({ 'anm-mkt-ga': cookie.get('_ga') || '' });
export const trackVisitPage = () => {
  ga('set', 'page', window.location.href);
  ga('send', 'pageview');
};

export const addTransaction = (
  id: string,
  price: string | number,
  name: string
) => {
  ga('ecommerce:addTransaction', {
    id,
    revenue: price
  });

  ga('ecommerce:addItem', {
    id,
    name,
    price,
    quantity: 1
  });

  ga('ecommerce:send');
};
