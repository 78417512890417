import { AUTH_COOKIE } from '@anm/constants/auth';
import * as cookie from '@anm/helpers/cookie';

import deleteRequest from '../helpers/logoutRequest';

export const animatronSecureWord = 'animatron:secure';
const logout = () => {
  deleteRequest();
  cookie.remove(AUTH_COOKIE);
  localStorage.clear();

  console.log('token-sharing: auth clean');
};

export default (token: string | null) =>
  token ? cookie.set(AUTH_COOKIE, token, 10, location.hostname.replace(/www.|assets./gi, '')) : logout();
