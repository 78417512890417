import curry from '@anm/helpers/curry';
import { Configs } from '@anm/hosting-player';
import { AuthProviders } from 'global';
import { User } from 'user';

import { EmailCampaignParams } from '../types';

import { track } from '.';
import {
  getMixpanel,
  getMixpanelPathProps,
  getSource,
  getTemplateInfoObj,
  getUserInfoProps,
  registerUserProperties,
  trackTemplate
} from './helpers';
import {
  CreateStreamProps,
  CreateVideoFromAISubmitProps,
  CreateVideoFromReducedTextProps,
  CreateVideoFromTextAsIsProps,
  LandingSaveParams,
  TrackTemplateEmailProps,
  TrackUploadProps,
  TrackWatchLanding
} from './types';

export const trackVisit = async (user: User | null) => {
  const params = { Source: getSource() };

  await getMixpanel().then(mixpanel => {
    mixpanel.register_once(params);
    mixpanel.people.set_once(params);
  });

  if (user) {
    await getMixpanel().then(mixpanel => mixpanel.identify(user.userIdAsString));
    return track('web.visit', getMixpanelPathProps());
  }
  return track('web.visit.guest', {
    ...getMixpanelPathProps(),
    UserAgent: window.navigator.userAgent
  });
};

export const trackVisitPage = (params = {}) => {
  track('web.visit.page', { ...params, ...getMixpanelPathProps() });
};

export const trackShareEvent = (serviceName: string) => track('web.calendar.share.try', { 'Share to': serviceName });

export const trackTemplateView = curry(trackTemplate)('web.templates.view');
export const trackTemplatePlay = curry(trackTemplate)('web.templates.play');
export const trackTemplateFormatSelect = (templateName: string, templateId: string, formatName: string) =>
  track('web.templates.format.select', {
    ...getTemplateInfoObj(templateName, templateId),
    'New Format': formatName
  });

export const trackLogin = (userProfile: User) => {
  registerUserProperties(userProfile);
  getMixpanel().then(mixpanel => {
    mixpanel.people.set({ ...getUserInfoProps(userProfile) });
  });
};

export const trackSignUp = (provider: AuthProviders, userProfile: User) => {
  registerUserProperties(userProfile);
  getMixpanel().then(mixpanel => {
    mixpanel.alias(userProfile.userIdAsString);
    mixpanel.people.set({
      $created: new Date().toISOString(),
      Place: 'Website',
      Product: userProfile.meta.product,
      Provider: provider,
      ...getUserInfoProps(userProfile)
    });
  });
  track('web.signup', { Provider: provider });
};

export const updateUserProfile = (props: { [key: string]: string }) =>
  getMixpanel().then(mixpanel => mixpanel.people.set(props));

export const mixpanelLogout = () =>
  getMixpanel().then(mixpanel => {
    mixpanel.reset();
  });

export const trackTemplatesSearch = (value: string, templateCount: number) =>
  track('web.templates.search', {
    Value: value,
    'Search amount': `${templateCount} templates`
  });

export const trackTemplatesFilterAspectRatio = (value: string) =>
  track('web.templates.filter.aspect-ratio', {
    Value: value
  });

export const trackVideosEmbedSettingsOpen = () =>
  track('web.videos.embed.settings.open', {
    Description: 'User opens the Embed settings'
  });

export const trackVideosShareTry = (isVideoHosted: boolean) =>
  track('web.videos.share.try', {
    Description: 'User tries to share a video but is asked to publish it first',
    IsVideoHosted: isVideoHosted
  });

export const trackVideosShareTo = (serviceName: string) =>
  track('web.videos.share.to', {
    Description: `User shared a video to ${serviceName}`
  });

export const trackVideoDescriptionChanged = () =>
  track('web.videos.description', {
    Description: 'User changes the description under the video'
  });

export const trackActivateEmbedToggle = () =>
  track('web.videos.embed.on', {
    Description: 'User activates the embed toggle'
  });

export const trackDeactivateEmbedToggle = () => {
  track('web.videos.embed.off', {
    Description: 'User de-activates the embed toggle'
  });
};

export const trackOpenVideoAppearance = () =>
  track('web.videos.appearance', {
    Description: 'User opens the Appearance settings'
  });

export const trackVideoUseCurrentFrame = () =>
  track('web.videos.appearance.thumbnail.current-frame', {
    Description: 'User clicks "Use current frame" button'
  });

export const trackVideoUploadThumbnail = () =>
  track('web.videos.appearance.thumbnail.upload', {
    Description: 'User uploads a thumbnail'
  });

export const trackVideoAppearanceChanged = (appearance: Configs) =>
  track('web.videos.appearance.change', {
    Description: 'User changes any of the appearance settings',
    Appearance: appearance
  });

export const trackOpenVideoPassword = () =>
  track('web.videos.password.open', {
    Description: 'User opens the Password settings'
  });

export const trackVideoPasswordOn = () =>
  track('web.videos.password.on', {
    Description: 'Users turns on the password'
  });

export const trackVideoPasswordOff = () =>
  track('web.videos.password.off', {
    Description: 'Users turns off the password'
  });

export const trackDuplicateVideo = () =>
  track('web.videos.duplicate', {
    Description: 'User duplicates a video'
  });

export const trackNewVideoFolder = () =>
  track('web.videos.new-folder', {
    Description: 'User creates a new folder'
  });

export const trackVideoRename = () =>
  track('web.video.rename', {
    Description: 'user renames a video'
  });

export const trackFolderRename = () =>
  track('web.folder.rename', {
    Description: 'user renames a folder'
  });

export const trackCopyEmbedCode = () =>
  track('web.video.embed-code-copy', {
    Description: 'copy code button click'
  });

export const trackCreateProjectFromUpload = (attrs: TrackUploadProps) =>
  track('web.videos.upload.add', {
    ...attrs,
    Description: 'When uploading a video'
  });

export const trackOpenLandingSettings = (URL: string) => {
  track('web.videos.landing.open', {
    URL,
    Description: 'User opens video landing page settings'
  });
};

export const trackSaveLandingSettings = (params: LandingSaveParams) => {
  track('web.videos.landing.save', {
    Description: 'User saves the landing page settings',
    ...params
  });
};
export const trackOpenVideoPreview = () => {
  track('web.videos.preview.open', {
    Description: 'Open preview'
  });
};

export const trackOpenEmailCampaign = () => {
  track('web.videos.email.open', {
    Description: 'User opens email campaigns page'
  });
};

export const trackCopyEmailCampaignCode = () => {
  track('web.videos.email.copy', {
    Description: 'User copies the video embed code'
  });
};

export const trackDownloadThumbnail = () => {
  track('web.videos.email.thumbnail.download', {
    Description: 'User clicks "download thumbnail"'
  });
};

export const trackAddURLParams = (params: EmailCampaignParams) => {
  track('web.videos.email.utm.add', {
    ...params,
    Description: 'User add URL parameters'
  });
};

export const trackLandingCTAClick = (URL: string) => {
  track('web.video.landing.cta.click', {
    URL,
    Description: 'Viewer clicks on a cta on a video landing page'
  });
};

export const trackWatchLanding = (props: TrackWatchLanding) => {
  track('web.video.watch.landing', {
    ...props
  });
};

export const trackTemplateSendEmail = (props: TrackTemplateEmailProps) => {
  track('web.template.sendemail', props);
};

export const trackOpenCreateVideoDropdown = () => {
  track('web.video.new.show', {
    Description: 'Create video popup is shown'
  });
};

export const trackCreateVideoUploadChoosen = () => {
  track('web.video.new.choose.upload', {
    Description: 'User selects "Upload" from the popup'
  });
};

export const trackCreateVideoTemplatesChoosen = () => {
  track('web.video.new.choose.templates', {
    Description: 'User selects "Templates" from the popup'
  });
};

export const trackCreateVideoStocksChoosen = () => {
  track('web.video.new.choose.stocks', {
    Description: 'User selects "Stocks" from the popup'
  });
};

export const trackCreateVideoBlankChoosen = () => {
  track('web.video.new.choose.blank', {
    Description: 'User selects "Blank Video" from the popup'
  });
};

export const trackCreateImageBlankChoosen = () => {
  track('web.image.new.choose.blank', {
    Description: 'User selects "Blank Image" from the popup'
  });
};

export const trackCreateVideoFromBlogChoosen = () => {
  track('web.video.new.choose.ai.blog', {
    Description: 'User selects "Blog post to video" from the popup'
  });
};

export const trackCreateVideoFromTextChoosen = () => {
  track('web.video.new.choose.ai.text', {
    Description: 'User selects "Text to video" from the popup'
  });
};

export const trackCreateVideoFromBlogSubmit = (props: CreateVideoFromAISubmitProps) => {
  track('web.video.new.choose.ai.blog.submit', {
    ...props,
    Description: 'User submits all the data to generate from blog'
  });
};

export const trackCreateVideoFromTextAsIsSubmit = (props: CreateVideoFromTextAsIsProps) => {
  track('web.video.new.choose.ai.text.as-is.submit', {
    ...props,
    Description: 'User submits all the data to generate from text, "as is"'
  });
};

export const trackCreateVideoFromReducedTextSubmit = (props: CreateVideoFromReducedTextProps) => {
  track('web.video.new.choose.ai.text.reduce.submit', {
    ...props,
    Description: 'User submits all the data to generate from text, with "reduce with AI"'
  });
};

export const trackCreateStream = ({ kind, schedule, destinationsCount }: CreateStreamProps) => {
  track('app.studio.stream.create', {
    Kind: kind,
    Destinations: destinationsCount,
    ...(schedule && { 'Scheduled At': new Date(schedule).toISOString() })
  });
};

export const trackMyStreamsClick = () => {
  track('web.mystreams.open');
};

export const trackMyProjectsClick = () => {
  track('web.myprojects.open');
};

export const trackMyStreamsUpcomingTabClick = () => {
  track('web.streams.upcoming');
};
export const trackMyStreamsInProgressTabClick = () => {
  track('web.streams.inprogress');
};

export const trackMyStreamsPastTabClick = () => {
  track('web.streams.past');
};

export const trackMyStreamsDestinationsTabClick = () => {
  track('web.streams.destinations');
};

export const trackMyStreamsAddNewDestinationClick = () => {
  track('web.streams.destinations.new');
};

export const trackMyStreamsSelectDestination = (type: string) => {
  track('web.streams.destinations.select', { type });
};

export const trackMyStreamsAddDestination = (type: string) => {
  track('web.streams.destinations.added', { type });
};

export const trackNewStreamDialogClickSelectFolder = () => {
  track('web.streams.dialog.select_folder');
};

export const trackNewStreamCloseDialog = () => {
  track('web.streams.dialog.cancel');
};

export const trackNewStreamOpenDialog = () => {
  track('web.streams.dialog.open');
};

export const trackOnBoardTwoOptionsShow = () => {
  track('web.onboarding.twoOptions.show');
};
export const trackOnBoardTwoOptionsOpenStudio = () => {
  track('web.onboarding.twoOptions.openStudio');
};
export const trackOnBoardTwoOptionsOpenEditor = () => {
  track('web.onboarding.twoOptions.openEditor');
};
export const trackOnBoardTwoOptionsClose = () => {
  track('web.onboarding.twoOptions.close');
};

export const trackCancelSwitchToStarter = () => {
  track('app.cancel.switched_to_starter');
};
export const trackCancelReason = (reason: string, userFeedback?: string) => {
  track('app.cancel.reason', {
    Reason: reason,
    Feedback: userFeedback || ' No feedback'
  });
};
export const trackCancelAskForReason = () => {
  track('app.cancel.ask_for_reason');
};
export const trackCancelShowBenefits = () => {
  track('app.cancel.show_benefits');
};
