import { ANIMATRON_ASSETS_TEST } from '@anm/constants/domains';
import { STREAMING_STUDIO_URL_TEST } from '@anm/constants/streaming';
import { DeepPartial } from 'helpers';

import { Config } from './types';

const configs: DeepPartial<Config> = {
  port: 3000,
  isProd: false,
  cdnUrl: `${ANIMATRON_ASSETS_TEST}landings/`,
  nextDevMode: false,
  streamingStudioUrl: STREAMING_STUDIO_URL_TEST,
  support: {
    intercom: {
      enable: true
    }
  },
  analytics: {
    google: {
      enable: true
    },
    facebook: {
      enable: true
    },
    mixpanel: {
      enable: true
    },
    fullstory: {
      enable: true
    }
  }
};

export default configs;
