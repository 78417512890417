import callAfterCall from '@anm/helpers/async/callAfterCall';
import isServer from '@anm/helpers/is/isServer';
import runOnlyDifferentArgs from '@anm/helpers/runOnlyDifferentArgs';
import { safeAsyncRun } from '@anm/helpers/safeOperation';
import sleep from '@anm/helpers/sleep';
import capitalize from '@anm/helpers/string/capitalize';
import { Configs } from '@anm/hosting-player';
import { AnimatronProduct, AuthProviders } from 'global';
import pickBy from 'lodash/fp/pickBy';
import { User } from 'user';

import * as facebookAnalytic from './facebookAnalytic';
import * as fullStory from './fullstory';
import * as googleAnalytic from './googleAnalytic';
import isUserAgentSafe from './helpers/isUserAgentSafe';
import logError from './helpers/logError';
import * as visitControl from './helpers/visitControl';
import * as hotjarAnalytic from './hotjar';
import * as mixpanelAnalytic from './mixpanelAnalytic';
import {
  CreateStreamProps,
  CreateVideoFromAISubmitProps,
  CreateVideoFromReducedTextProps,
  CreateVideoFromTextAsIsProps
} from './mixpanelAnalytic';
import { AnalyticProviders, EmailCampaignParams } from './types';
export * from './types';

export type TrackSignUpEventProps = {
  product: AnimatronProduct;
  provider: AuthProviders;
  userProfile: User;
};

export type TrackVisitProps = {
  product: AnimatronProduct;
  userProfile: User | null;
};

type TrackLoginProps = {
  product: AnimatronProduct;
  userProfile: User;
};

let isInitialized = false;

export const initTrackerLibs = (
  { google, hotjar, facebook, mixpanel, fullstory }: AnalyticProviders,
  product: AnimatronProduct
) => {
  if (!isServer() && !isInitialized && isUserAgentSafe()) {
    if (google.enable) {
      googleAnalytic.load();
      googleAnalytic.init(google);
    }

    // this user agent is used by pagespeed. we also need to disable analytics in this case except GA
    const isLighthouse = window.navigator.userAgent.includes('Chrome-Lighthouse');

    if (!isLighthouse) {
      if (facebook.enable) {
        facebookAnalytic.load();
        facebookAnalytic.init(facebook.id);
      }

      if (mixpanel.enable) {
        mixpanelAnalytic.load(mixpanel.libUrl);
        mixpanelAnalytic.init({
          product,
          id: mixpanel.id,
          apiHost: mixpanel.apiHost
        });
      }

      if (fullstory.enable) {
        fullStory.init(fullstory.id);
        fullStory.load();
      }

      if (hotjar.enable) {
        hotjarAnalytic.load(hotjar.id);
      }
    }
  }

  isInitialized = true;
};

export const clearVisitStore = visitControl.clear;

export const waitForAnalyticFinish = async () => {
  await sleep(0);
  await mixpanelAnalytic.waitForMixpanelFinish();
};

export const addTransaction = (price: string | number, userId: string, subscriptionName: string) => {
  const transactionId = `${userId} - ${Date.now()}`;
  googleAnalytic.addTransaction(transactionId, price, subscriptionName);
  facebookAnalytic.addTransaction(transactionId, price, subscriptionName);
};

export const trackVisitAsync = async ({ userProfile, product }: TrackVisitProps) =>
  safeAsyncRun(async () => {
    const userId = userProfile && userProfile.userId;

    if (!visitControl.canVisit(userId)) return;

    await mixpanelAnalytic.registerCommonProperties(product);
    await mixpanelAnalytic.trackVisit(userProfile);

    fullStory.setUser(userProfile);

    visitControl.visit(userId);
  }, logError);

export const trackVisit = callAfterCall(trackVisitAsync);

export const trackVisitPage = (_pageName: string, params = {}) => {
  googleAnalytic.trackVisitPage();
  facebookAnalytic.trackVisitPage();
  mixpanelAnalytic.trackVisitPage(params);
};

export const trackTemplateView = (templateName: string, templateId: string) => {
  mixpanelAnalytic.trackTemplateView(templateName, templateId);
};

export const trackShareEvent = (serviceName: string) => {
  const capitalizedName = capitalize(serviceName);

  mixpanelAnalytic.trackShareEvent(capitalizedName);
};

export const trackTemplatePlay = runOnlyDifferentArgs((templateName: string, templateId: string) => {
  mixpanelAnalytic.trackTemplatePlay(templateName, templateId);
});

export const trackTemplateFormatSelect = (templateName: string, templateId: string, formatName: string) => {
  mixpanelAnalytic.trackTemplateFormatSelect(templateName, templateId, formatName);
};

export const trackSignUpEvent = ({ userProfile, provider, product }: TrackSignUpEventProps) => {
  facebookAnalytic.trackSignUp(product, provider);
  mixpanelAnalytic.trackLogin(userProfile);

  trackVisit({
    userProfile,
    product
  });
};

export const trackLogin = ({ userProfile, product }: TrackLoginProps) => {
  mixpanelAnalytic.trackLogin(userProfile);

  trackVisit({
    userProfile,
    product
  });
};

export const logout = () => {
  mixpanelAnalytic.mixpanelLogout();
};

export const getHeaders = async () =>
  pickBy(Boolean)({
    ...googleAnalytic.getGaHeaders(),
    ...(await mixpanelAnalytic.getMixpanelHeaders())
  });

export const trackTemplatesSearch = (value: string, templateCount: number) =>
  mixpanelAnalytic.trackTemplatesSearch(value, templateCount);

export const trackTemplatesFilterAspectRatio = (value: string) =>
  mixpanelAnalytic.trackTemplatesFilterAspectRatio(value);

export const trackVideosEmbedSettingsOpen = () => mixpanelAnalytic.trackVideosEmbedSettingsOpen();

export const trackVideosShareTry = (isVideoHosted: boolean) => mixpanelAnalytic.trackVideosShareTry(isVideoHosted);

export const trackVideosShareTo = (serviceName: string) => mixpanelAnalytic.trackVideosShareTo(serviceName);

export const trackVideoDescriptionChanged = () => mixpanelAnalytic.trackVideoDescriptionChanged();

export const trackActivateEmbedToggle = () => mixpanelAnalytic.trackActivateEmbedToggle();

export const trackDeactivateEmbedToggle = () => mixpanelAnalytic.trackDeactivateEmbedToggle();

export const trackOpenVideoAppearance = () => mixpanelAnalytic.trackOpenVideoAppearance();

export const trackVideoUseCurrentFrame = () => mixpanelAnalytic.trackVideoUseCurrentFrame();

export const trackVideoUploadThumbnail = () => mixpanelAnalytic.trackVideoUploadThumbnail();

export const trackVideoAppearanceChanged = (appearance: Configs) =>
  mixpanelAnalytic.trackVideoAppearanceChanged(appearance);

export const trackOpenVideoPassword = () => mixpanelAnalytic.trackOpenVideoPassword();

export const trackVideoPasswordOn = () => {
  mixpanelAnalytic.trackVideoPasswordOn();
};

export const trackVideoPasswordOff = () => {
  mixpanelAnalytic.trackVideoPasswordOff();
};

export const trackDuplicateVideo = () => {
  mixpanelAnalytic.trackDuplicateVideo();
};

export const trackNewVideoFolder = () => {
  mixpanelAnalytic.trackNewVideoFolder();
};

export const trackFolderRename = () => {
  mixpanelAnalytic.trackFolderRename();
};

export const trackVideoRename = () => {
  mixpanelAnalytic.trackVideoRename();
};

export const trackCopyEmbedCode = () => {
  mixpanelAnalytic.trackCopyEmbedCode();
};

export const trackCreateProjectFromUpload = (props: mixpanelAnalytic.TrackUploadProps) => {
  mixpanelAnalytic.trackCreateProjectFromUpload(props);
};

export const trackOpenLandingSettings = (url: string) => {
  mixpanelAnalytic.trackOpenLandingSettings(url);
};

export const trackSaveLandingSettings = (props: mixpanelAnalytic.LandingSaveParams) => {
  mixpanelAnalytic.trackSaveLandingSettings(props);
};

export const updateUserProfile = (props: { [key: string]: string }) => {
  mixpanelAnalytic.updateUserProfile(props);
};

export const trackOpenVideoPreview = () => {
  mixpanelAnalytic.trackOpenVideoPreview();
};

export const trackOpenEmailCampaign = () => {
  mixpanelAnalytic.trackOpenEmailCampaign();
};

export const trackCopyEmailCampaignCode = () => {
  mixpanelAnalytic.trackCopyEmailCampaignCode();
};

export const trackDownloadThumbnail = () => {
  mixpanelAnalytic.trackDownloadThumbnail();
};

export const trackAddURLParams = (params: EmailCampaignParams) => {
  mixpanelAnalytic.trackAddURLParams(params);
};

export const trackLandingCTAClick = (url: string) => {
  mixpanelAnalytic.trackLandingCTAClick(url);
};

export const trackTemplateSendEmail = (props: mixpanelAnalytic.TrackTemplateEmailProps) => {
  mixpanelAnalytic.trackTemplateSendEmail(props);
};

export const trackOpenCreateVideoDropdown = () => {
  mixpanelAnalytic.trackOpenCreateVideoDropdown();
};

export const trackCreateVideoUploadChoosen = () => {
  mixpanelAnalytic.trackCreateVideoUploadChoosen();
};

export const trackCreateVideoTemplatesChoosen = () => {
  mixpanelAnalytic.trackCreateVideoTemplatesChoosen();
};

export const trackCreateVideoStocksChoosen = () => {
  mixpanelAnalytic.trackCreateVideoStocksChoosen();
};

export const trackCreateVideoBlankChoosen = () => {
  mixpanelAnalytic.trackCreateVideoBlankChoosen();
};

export const trackCreateImageBlankChoosen = () => {
  mixpanelAnalytic.trackCreateImageBlankChoosen();
};

export const trackCreateVideoFromBlogChoosen = () => {
  mixpanelAnalytic.trackCreateVideoFromBlogChoosen();
};

export const trackCreateVideoFromTextChoosen = () => {
  mixpanelAnalytic.trackCreateVideoFromTextChoosen();
};

export const trackCreateVideoFromBlogSubmit = (props: CreateVideoFromAISubmitProps) => {
  mixpanelAnalytic.trackCreateVideoFromBlogSubmit(props);
};

export const trackCreateVideoFromTextAsIsSubmit = (props: CreateVideoFromTextAsIsProps) => {
  mixpanelAnalytic.trackCreateVideoFromTextAsIsSubmit(props);
};

export const trackCreateVideoFromReducedTextSubmit = (props: CreateVideoFromReducedTextProps) => {
  mixpanelAnalytic.trackCreateVideoFromReducedTextSubmit(props);
};

export const trackCreateStream = (props: CreateStreamProps) => {
  mixpanelAnalytic.trackCreateStream(props);
};

export const trackMyStreamsClick = () => {
  mixpanelAnalytic.trackMyStreamsClick();
};

export const trackMyProjectsClick = () => {
  mixpanelAnalytic.trackMyProjectsClick();
};

export const trackMyStreamsUpcomingTabClick = () => {
  mixpanelAnalytic.trackMyStreamsUpcomingTabClick();
};

export const trackMyStreamsInProgressTabClick = () => {
  mixpanelAnalytic.trackMyStreamsInProgressTabClick();
};

export const trackMyStreamsDestinationsTabClick = () => {
  mixpanelAnalytic.trackMyStreamsDestinationsTabClick();
};

export const trackMyStreamsPastTabClick = () => {
  mixpanelAnalytic.trackMyStreamsPastTabClick();
};

export const trackMyStreamsAddNewDestinationClick = () => {
  mixpanelAnalytic.trackMyStreamsAddNewDestinationClick();
};
export const trackMyStreamsSelectDestination = (type: string) => {
  mixpanelAnalytic.trackMyStreamsSelectDestination(type);
};

export const trackMyStreamsAddDestination = (type: string) => {
  mixpanelAnalytic.trackMyStreamsAddDestination(type);
};

export const trackNewStreamDialogClickSelectFolder = () => {
  mixpanelAnalytic.trackNewStreamDialogClickSelectFolder();
};

export const trackNewStreamCloseDialog = () => {
  mixpanelAnalytic.trackNewStreamCloseDialog();
};

export const trackNewStreamOpenDialog = () => {
  mixpanelAnalytic.trackNewStreamOpenDialog();
};

export const trackOnBoardTwoOptionsClose = () => {
  mixpanelAnalytic.trackOnBoardTwoOptionsClose();
};
export const trackOnBoardTwoOptionsOpenEditor = () => {
  mixpanelAnalytic.trackOnBoardTwoOptionsOpenEditor();
};
export const trackOnBoardTwoOptionsOpenStudio = () => {
  mixpanelAnalytic.trackOnBoardTwoOptionsOpenStudio();
};
export const trackOnBoardTwoOptionsShow = () => {
  mixpanelAnalytic.trackOnBoardTwoOptionsShow();
};

export const trackCancelShowBenefits = () => {
  mixpanelAnalytic.trackCancelShowBenefits();
};
export const trackCancelAskForReason = () => {
  mixpanelAnalytic.trackCancelAskForReason();
};
export const trackCancelReason = (reason: string, userFeedback?: string) => {
  mixpanelAnalytic.trackCancelReason(reason, userFeedback);
};
export const trackCancelSwitchToStarter = () => {
  mixpanelAnalytic.trackCancelSwitchToStarter();
};
