import { DialogProps } from '@anm/components/modals/Dialog';
import getFromCdn from '@anm/helpers/getFromCdn';
import {
  FOOTER_HEIGHT,
  FOOTER_HEIGHT_MOBILE,
  HEADER_HEIGHT,
  HEADER_HEIGHT_MOBILE,
  INPUT_FIELD_HEIGHT
} from '@anm/styles/constants';
import styled, { css, ThemedCssFunction } from 'styled-components';

type MediaSizes = {
  [key in keyof DesktopFirstSizes<number>]: ThemedCssFunction<any>;
};

type DesktopFirstSizes<T> = {
  giant: T;
  desktop: T;
  desktop_small: T;
  tablet: T;
  phone: T;
};

type MobileFirstSizes = {
  desktop: number;
  laptop: number;
  tablet: number;
  mobile: number;
};

export const setMedia = (size: number) => (strings: TemplateStringsArray, ...args: string[]) => css`
  @media (max-width: ${size}px) {
    ${css(strings, ...args)};
  }
`;

export const desktopFirstSizes: DesktopFirstSizes<number> = {
  giant: 1919,
  desktop: 1439,
  desktop_small: 1023,
  tablet: 768,
  phone: 430
};

const mobileFirstSizes: MobileFirstSizes = {
  mobile: 576,
  tablet: 768,
  laptop: 992,
  desktop: 1200
};

export const device = {
  mobile: `(min-width: ${mobileFirstSizes.mobile}px)`,
  tablet: `(min-width: ${mobileFirstSizes.tablet}px)`,
  laptop: `(min-width: ${mobileFirstSizes.laptop}px)`,
  desktop: `(min-width: ${mobileFirstSizes.desktop}px)`
};

export const media: MediaSizes = Object.keys(desktopFirstSizes).reduce<MediaSizes>(
  (acc: MediaSizes, label: keyof DesktopFirstSizes<number>) => {
    acc[label] = setMedia(desktopFirstSizes[label]);
    return acc;
  },
  {} as MediaSizes
);

export const landscapeMedia = (maxHeight = 500, maxWidth = desktopFirstSizes.phone) =>
  ((strings: TemplateStringsArray, ...args: string[]) => css`
    @media (max-height: ${maxHeight}px) and (max-width: ${maxWidth}px) and (orientation: landscape) {
      ${css(strings, ...args)};
    }
  `) as ThemedCssFunction<any>;

export const palette = css`
  :root {
    --white: #ffffff;
    --gray_10: #f4f6f7;
    --gray_20: #f3f6f7;
    --gray_40: #e9eef1;
    --gray_50: #e0e7eb;
    --gray_100: #ccd7dd;
    --gray_100_20: rgba(0, 56, 87, 0.2);
    --gray_200: #bcc6cc;
    --gray_300: #aab3b9;
    --gray_400: #8b9499;
    --gray_500: #71777a;
    --gray_600: #575c5e;
    --gray_700: #3f4244;
    --gray_800: #333536;
    --gray_900: #292a2b;
    --gray_950: #1b1c1c;

    --blue_100_10: rgb(48, 174, 242, 0.1);
    --blue_100_20: rgb(48, 174, 242, 0.2);
    --blue_20: #d9f2ff;
    --blue_50: #a5dfff;
    --blue_100: #30aef2;
    --blue_200: #0693e0;

    --error: #ee3d3d;

    --gray_to_black: sepia(36%) saturate(760%) hue-rotate(164deg) brightness(30%) contrast(98%);
    --any_to_gray_20: brightness(0) saturate(100%) invert(62%) sepia(99%) saturate(0) hue-rotate(84deg) brightness(135%)
      contrast(137%);
    --any_to_gray_400: brightness(0) saturate(100%) invert(58%) sepia(16%) saturate(152%) hue-rotate(158deg)
      brightness(96%) contrast(93%);
    --any_to_gray_600: brightness(0) saturate(100%) invert(35%) sepia(11%) saturate(207%) hue-rotate(151deg)
      brightness(94%) contrast(87%);
    --any_to_gray_800: brightness(0) saturate(100%) invert(16%) sepia(4%) saturate(464%) hue-rotate(155deg)
      brightness(100%) contrast(88%);
  }
`;

export const truncate = (width: string) => `
    width: ${width};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `;

export const truncateMultiline = (linesCount: string | number) => `
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: ${linesCount};
    -webkit-box-orient: vertical;
  `;

const stretchToScreenHeightOnTablet = media.tablet`
  min-height: calc(100vh - ${HEADER_HEIGHT_MOBILE} - ${FOOTER_HEIGHT_MOBILE});
`;

export const stretchToScreenHeight = css`
  min-height: calc(100vh - ${HEADER_HEIGHT} - ${FOOTER_HEIGHT});
  ${stretchToScreenHeightOnTablet};
`;

export const fadeInAnimation = css`
  @keyframes fadeIn {
    from {
      opacity: 0;
      visibility: hidden;
    }

    to {
      opacity: 1;
      visibility: visible;
    }
  }
`;

export const fadeOutAnimation = css`
  @keyframes fadeOut {
    from {
      opacity: 1;
      visibility: visible;
    }

    to {
      opacity: 0;
      visibility: hidden;
    }
  }
`;

export const flexCenter = css`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const absoluteCenter = css`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const stretchBlock = css`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export const filterBlockPadding = css`
  padding-left: 30px;
  padding-right: 30px;
`;

export const roundedShadowWhiteBg = css`
  position: relative;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0px 2px 10px rgba(1, 83, 130, 0.1);
`;

export const mobileMenuItem = css`
  text-decoration: none;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  color: #292a2b;
  display: block;
  height: 69px;
  line-height: 69px;
  flex: 1;
  text-align: left;
`;

export const fillTheHeight = css`
  display: flex;
  flex: 1;
`;

export const contentAdaptiveStyles = media.tablet`
  width: 100%;
  padding: 30px 20px;
  margin: 0;
`;

export const centeredInFullHeight = (minHeight = HEADER_HEIGHT) => css`
  height: 100%;
  min-height: calc(100vh - ${minHeight});
  ${flexCenter};
  flex: 1;
  padding-top: 30px;
  padding-bottom: 30px;
`;

export const fromButtonToLink = css`
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  color: #292a2b;
  text-transform: none;
  background-color: transparent;
  padding: 0;
  &:hover {
    color: #2294df;
  }
`;

export const getFadeInAnimation = (duration = 1) => css`
  animation-duration: ${duration}s;
  animation-fill-mode: forwards;
  animation-name: fadeIn;
  ${fadeInAnimation};
`;

export const getFadeOutAnimation = (duration = 1) => css`
  animation-duration: ${duration}s;
  animation-fill-mode: forwards;
  animation-name: fadeOut;
  ${fadeOutAnimation};
`;

export const scaleIn = css`
  animation-duration: 0.3s;
  animation-fill-mode: forwards;
  animation-name: scaleIn;
  @keyframes scaleIn {
    from {
      transform: scale(1);
      visibility: hidden;
    }

    to {
      transform: scale(1.2);
      visibility: visible;
    }
  }
`;

export const rightToLeftMenuAppearing = css`
  animation-name: menuIn;
  animation-duration: 0.2s;
  animation-fill-mode: forwards;
  @keyframes menuIn {
    from {
      transform: translateX(100%);
    }
    to {
      transform: translateX(0);
    }
  }
`;

export const ringingBell = css`
  animation: ring 4s 0.2s ease-in-out infinite;
  transform-origin: 50% 4px;
  @keyframes ring {
    0% {
      transform: rotate(0);
    }
    1% {
      transform: rotate(30deg);
    }
    3% {
      transform: rotate(-28deg);
    }
    5% {
      transform: rotate(34deg);
    }
    7% {
      transform: rotate(-32deg);
    }
    9% {
      transform: rotate(30deg);
    }
    11% {
      transform: rotate(-28deg);
    }
    13% {
      transform: rotate(26deg);
    }
    15% {
      transform: rotate(-24deg);
    }
    17% {
      transform: rotate(22deg);
    }
    19% {
      transform: rotate(-20deg);
    }
    21% {
      transform: rotate(18deg);
    }
    23% {
      transform: rotate(-16deg);
    }
    25% {
      transform: rotate(14deg);
    }
    27% {
      transform: rotate(-12deg);
    }
    29% {
      transform: rotate(10deg);
    }
    31% {
      transform: rotate(-8deg);
    }
    33% {
      transform: rotate(6deg);
    }
    35% {
      transform: rotate(-4deg);
    }
    37% {
      transform: rotate(2deg);
    }
    39% {
      transform: rotate(-1deg);
    }
    41% {
      transform: rotate(1deg);
    }

    43% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(0);
    }
  }
`;

export const PlyrVideoTabletStyles = media.tablet`
    height: auto;
`;

export const inputFieldStyles = css`
  height: ${INPUT_FIELD_HEIGHT};
  line-height: 1;
  border-radius: 5px;
  box-shadow: ${({ theme }) => theme.inputShadow};
  border: solid 1px ${({ theme }) => theme.inputBorder};
  width: 100%;
  padding: 15px;
  font-size: 16px;
  background-color: ${({ theme }) => theme.inputBg};
  color: ${({ theme }) => theme.textColor};
`;

export const flexColumn = css`
  display: flex;
  flex-direction: column;
`;

export const flexRow = css`
  display: flex;
  flex-direction: row;
`;

export const Row = styled.div`
  ${flexRow};
  flex-wrap: wrap;
  justify-content: space-evenly;
`;

export const UserFormRow = styled.div`
  flex-grow: 1;
  align-items: center;
  display: flex;
  justify-content: stretch;
  flex-wrap: wrap;
  margin: 0 -10px 6px;
  > * {
    flex: 1 1 180px;
    margin: 0 10px;
  }
`;

export const dashboardDescriptionStyles = css`
  color: #919eaa;
  padding: 0 5px;
  font-size: 13px;
  line-height: 16px;
  margin: 14px 0 12px;
`;

const dashboardTitleBlockStylesAdaptiveStyles = media.tablet`
  max-width: 100%;
`;

export const dashboardTitleBlockStyles = (color: string) => css`
  max-width: 100%;
  width: 100%;
  color: ${color};
  ${dashboardTitleBlockStylesAdaptiveStyles};
`;

export const blueFilter = css`
  filter: invert(50%) sepia(70%) saturate(523%) hue-rotate(146deg) brightness(100%) contrast(90%) opacity(70%);
`;

export const redFilter = css`
  filter: grayscale(100%) brightness(40%) sepia(100%) hue-rotate(-50deg) saturate(600%) contrast(0.8);
`;

export const blackFilter = css`brightness(0) saturate(100%)`;

const myProjectsItemBottomAdaptiveStyles = media.tablet`
  padding: 7px 3px 3px 7px;
`;

export const myProjectsItemBottomStyles = css`
  display: flex;
  flex-direction: column;
  border-radius: 0 0 5px 5px;
  padding: 14px 10px 15px 15px;
  border: 1px solid rgba(1, 83, 130, 0.2);
  border-top: none;
  ${myProjectsItemBottomAdaptiveStyles};
`;

export const folderIconBlock = css`
  &:before {
    display: block;
    content: '';
    width: 36px;
    height: 36px;
    margin-right: 19px;
    flex-shrink: 0;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url(${getFromCdn('images/dashboard/folder.svg')})};
  }
`;

export const AlertText = styled.p`
  color: #292a2b;
  line-height: 24px;
  font-weight: 600;
  text-align: center;
  margin: 0;
`;

export const checkBlurSupport = () =>
  CSS.supports('( backdrop-filter: blur(1px) ) or ( -webkit-backdrop-filter: blur(1px) )');

export const hiddenOnSmallDesktop = media.desktop_small`
    display: none;
`;

export const centeredMobileWidthStyles = css`
  margin: 0 auto;
  padding: 22px 19px;
`;

export const dropDownArrow = css`
  &:before,
  &:after {
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 7.5px 10px 7.5px;
    position: absolute;
    top: 1px;
    margin-left: auto;
    margin-right: auto;
    transform: translateY(-100%);
    z-index: 1;
    border-color: transparent transparent ${({ theme }) => theme.dropdownAndModalBg} transparent;
  }
  &:after {
    border-width: 0 8.5px 10px 8.5px;
    transform: translateY(-105%);
    z-index: 0;
    border-color: transparent transparent ${({ theme }) => theme.dropdownArrowSideBg} transparent;
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Arrow = styled.span`
  height: 20px;
  width: 6px;
  flex-shrink: 0;
  margin: 0 14px;
  background-image: url(${getFromCdn('images/dashboard/breadcrumb-arrow-thin.svg')});
  background-repeat: no-repeat;
  background-position: center;
  background-size: 6px 10px;
`;

export const grayFilter = css`
  filter: invert(31%) sepia(17%) saturate(513%) hue-rotate(165deg) brightness(110%) contrast(35%);
`;

export const blueDarkFilters = css`
  filter: invert(57%) sepia(40%) saturate(2917%) hue-rotate(161deg) brightness(98%) contrast(101%);
`;

export const grayToBlackFilter = css`
  filter: sepia(36%) saturate(760%) hue-rotate(164deg) brightness(30%) contrast(98%);
`;

export const cardCouponSectionItemStyles = css`
  display: flex;
  flex: 1 1 200px;
  margin: 10px;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  min-height: 100px;
`;

export const CloseButton = styled.div<DialogProps>`
  position: absolute;
  top: 20px;
  right: 20px;
  width: 14px;
  height: 14px;
  background: url(${getFromCdn('images/icons/cross.svg')}) no-repeat 0 0 transparent;
  background-size: 14px;
  cursor: pointer;
  pointer-events: all;
  z-index: 1;
  &:hover {
    filter: brightness(50%);
  }
  ${({ closeIconColor }) =>
    closeIconColor === 'black' &&
    css`
      filter: brightness(0.2);
    `}
`;

export const subscriptionModalSidePaddings = css`
  padding-left: 16px;
  padding-right: 16px;
  @media ${device.mobile} {
    padding-left: 30px;
    padding-right: 30px;
  }
`;

export const subscriptionModalSectionWrapperStyles = css`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  ${subscriptionModalSidePaddings};
`;

export const longBorderDashesStyles = css`
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='10' ry='10' stroke='%230094B5FF' stroke-width='2' stroke-dasharray='5.6' stroke-dashoffset='22' stroke-linecap='square'/%3e%3c/svg%3e");
`;

export const InfoLabel = styled.div<{ size?: number }>`
  cursor: pointer;
  margin-left: 8px;
  width: ${({ size }) => size || 15}px;
  height: ${({ size }) => size || 15}px;
  background: url(${getFromCdn('images/icons/i-icon.svg')}) no-repeat center/cover;
`;

export const slideInAnimation = css`
  @keyframes slideInBanner {
    0% {
      clip-path: polygon(0 0, 0 0, 0 100%, 0% 100%);
    }
    100% {
      clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 100%);
    }
  }
`;

export const moveInAnimation = css`
  @keyframes moveIn {
    0% {
      transform: translate(-25px);
    }
    100% {
      transform: translate(0);
    }
  }
`;

export const commonInputStyles = css<{ isFocused?: boolean; borderColor?: string }>`
  border-radius: 5px;
  box-sizing: border-box;
  border: 1px solid ${({ borderColor, isFocused }) => (isFocused ? '#30AEF2' : borderColor || '#ccdde6')};
  ::placeholder {
    color: #8b9499;
  }
  &:focus {
    outline: none;
  }
`;

export const customScrollbar = css`
  &::-webkit-scrollbar {
    background-color: ${({ theme }) => theme.webkitScrollbarBg};
    width: 16px;
  }

  &::-webkit-scrollbar-track {
    background-color: ${({ theme }) => theme.webkitScrollbarTrackBg};
  }

  &::-webkit-scrollbar-thumb {
    background-color: #babac0;
    background-color: ${({ theme }) => theme.webkitScrollbarThumbBg};
    border-radius: 16px;
    border: 4px solid ${({ theme }) => theme.webkitScrollbarTrackBg};
  }

  &::-webkit-scrollbar-button {
    display: none;
  }
`;

export const hiddenScrollBar = css`
  & ::-webkit-scrollbar {
    display: none;
  }
`;

export const osScrollBar = css`
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
`;

export const whiteShadowNavButtonActiveStyles = css`
  background-color: var(--white);
  box-shadow: 0 2px 10px rgba(1, 83, 130, 0.1);
  color: var(--gray_900);
`;
