const userVisitStorageName = 'analytic:visit:user';
const guestVisitStorageName = 'analytic:visit:guest';

export const clear = () => {
  localStorage.removeItem(guestVisitStorageName);
  localStorage.removeItem(userVisitStorageName);
};

export const canVisit = (userId: string | null) => {
  const storedUserId = localStorage.getItem(userVisitStorageName);
  const storedGuest = localStorage.getItem(guestVisitStorageName);

  if (storedUserId && storedGuest) {
    console.error('Error: Analytic: both guest and user visit were stored');
    return false;
  }

  const isFirstVisit = !storedUserId && !storedGuest;
  const isUserVisit = userId && (!storedUserId || userId !== storedUserId);

  return isFirstVisit || isUserVisit;
};

export const visit = (userId: string | null) => {
  if (userId) {
    localStorage.removeItem(guestVisitStorageName);
    localStorage.setItem(userVisitStorageName, userId);
  } else {
    localStorage.removeItem(userVisitStorageName);
    localStorage.setItem(guestVisitStorageName, 'true');
  }
};
